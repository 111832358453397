import React from 'react';
// import ReactDOM from 'react-dom';

class Header extends React.Component {
  render() {
    return (
      <header id="landing">
        <div className="row">
          <div className="col-md-12 logo">
            CRYPTOCATS
          </div>
          <div className="col-md-12 heading2">
            <p>Collectible 8-bit Cats on Ethereum Blockchain</p>
          </div>
        </div>
      </header>
    );
  }
}

export default Header;