// import './App.css';
import React, { Component } from 'react';
import axios from 'axios';
import Header from './components/Header';
import WrapModal from './components/WrapModal';
import UnwrapModal from './components/UnwrapModal';
import FilterButtons from './components/FilterButtons';
import ConnectMetamaskButton from './components/ConnectMetamaskButton';
import CatCard from './components/CatCard';
import Web3Banner from './components/Web3Banner';
import Web3 from 'web3';
import CryptoCat from './web3/CryptoCat';
import WrappedCryptoCat from './web3/WrappedCryptoCat';
import About from './pages/About';
import Explorer from './pages/Explorer';
import Detail from './pages/Detail';

import detectEthereumProvider from '@metamask/detect-provider';

const ccatAddress = process.env.REACT_APP_CCAT_ADDRESS;
const wccatAddress = process.env.REACT_APP_WCCAT_ADDRESS;
const ccatApiUrl = process.env.REACT_APP_API_URL + "/listing";

const requiredConfirmations = parseInt(process.env.REACT_APP_TX_CONFIRMATIONS);
class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filterType: "CCAT",
      ccatOwnedByAddress: [],
      wccatOwnedByAddress: [],
      catIdToWrap: "",
      catIdToUnwrap: "",
      walletAddress: "",
      showWeb3ConnectButton: true,
      ccatContractAddress: ccatAddress,
      wccatContractAddress: wccatAddress,
      catListIsLoading: false
    }

    console.log("CCAT Address: " + ccatAddress);
    console.log("WCCAT Address: " + wccatAddress);

    this.toggleFilterType = this.toggleFilterType.bind(this);
    this.showWrapModal = this.showWrapModal.bind(this);
    this.showUnwrapModal = this.showUnwrapModal.bind(this);
    this.connectWeb3Account = this.connectWeb3Account.bind(this);
    this.detectAndLoadWeb3Provider = this.detectAndLoadWeb3Provider.bind(this);
    this.getShortWalletAddress = this.getShortWalletAddress.bind(this);
    this.getCCATowned = this.getCCATowned.bind(this);
    this.getWCCATowned = this.getWCCATowned.bind(this);
    this.reloadCCATList = this.reloadCCATList.bind(this);
    this.reloadWCCATList = this.reloadWCCATList.bind(this);
    this.reloadCats = this.reloadCats.bind(this);
    this.setUrlPath = this.setUrlPath.bind(this);

    // this.setUrlPath();
    var htmlElement = document.getElementsByTagName('html');
    console.log("HTML ==>", htmlElement.style);
  }

  componentDidMount() {
    this.detectAndLoadWeb3Provider();
    this.setUrlPath();
  }

  setUrlPath() {
    var urlPath = window.location.pathname;
    var urlPathSplit = urlPath.split("/");
    console.log("PATH ====>", urlPath, urlPathSplit);
    this.setState({ urlPath, urlPathSplit });
    if (urlPathSplit[1] === "wrap" && urlPathSplit[2] && urlPathSplit[2] === "wccat") {
      this.toggleFilterType("WCCAT");
    } 
    // else if (urlPathSplit[1] === "wrap" && urlPathSplit[2] && !isNaN(urlPathSplit[2]) && !isNaN(parseFloat(urlPathSplit[2]))) {
    //   if (!this.state.wrapModalClosed) {
    //     console.log("Wrapper for cat => " + urlPathSplit[2]);
    //     that.setState({ catIdToWrap: urlPathSplit[2] });
    //     var wrapModal = document.getElementById('wrapCatModal');
    //     console.log("---- MODAL -----");
    //     console.log(wrapModal);
    //     document.getElementById('wrapCatModal').style.display = "block";
    //     wrapModal.classList.add("show");        
    //   }

    // }

  }

  reloadCats() {
    if (this.filterType === "CCAT") {
      this.reloadCCATList();
    } else {
      this.reloadWCCATList();
    }
  }

  reloadCCATList() {
    var that = this;
    that.setState({
      catListIsLoading: true
    });
    that.getCCATowned((that.state.walletAddress), (ccatList) => {
      that.setState({
        ccatOwnedByAddress: ccatList,
        catListIsLoading: false
      })
    });
  }

  reloadWCCATList() {
    var that = this;
    that.setState({
      catListIsLoading: true
    });
    that.getWCCATowned((that.state.walletAddress), (wccatList) => {
      that.setState({
        wccatOwnedByAddress: wccatList,
        catListIsLoading: false
      })
    });
  }

  getCCATowned(address, callback) {
    axios.get(ccatApiUrl + "/ccat/" + address)
    .then((result) => {
      console.log("CCAT API resp:", result);
      if (result !== undefined && result.data !== undefined && result.data.ccat !== undefined) {
        var catIds = Object.keys(result.data.ccat);
        console.log("CCAT list => ", catIds);
        callback(catIds);
      } else {
        callback([]);
      }
    })
    .catch((err) => {
      console.log("error retrieving CCAT list", err);
      callback([]);
    })
  }

  getWCCATowned(address, callback) {
    axios.get(ccatApiUrl + "/wccat/" + address)
    .then((result) => {
      console.log("WCCAT API resp:", result);
      if (result !== undefined && result.data !== undefined && result.data.ccat !== undefined) {
        var catIds = Object.keys(result.data.ccat);
        console.log("WCCAT list => ", catIds);
        callback(catIds);
      } else {
        callback([]);
      }
    })
    .catch((err) => {
      console.log("error retrieving WCCAT list", err);
      callback([]);
    })
  }

  async detectAndLoadWeb3Provider() {
    const provider = await detectEthereumProvider();
 
    if (provider) {
      console.log('Ethereum successfully detected!')
      console.log(provider);

      const web3 = new Web3(provider);

      var cryptoCat = new CryptoCat(web3, ccatAddress, wccatAddress, requiredConfirmations);
      var wrappedCryptoCat = new WrappedCryptoCat(web3, wccatAddress, requiredConfirmations);

      const chainId = await provider.request({
        method: 'eth_chainId'
      })

      var networkName = "";

      console.log("Web3 Chain id => " + chainId);

      if (chainId ==="0x4") {
        networkName = "Rinkeby"
      } else if (chainId ==="0x1") {
        networkName = "Mainnet"
      } else {
        networkName = "Unknown";
      }

      this.setState({
        web3Provider: provider,
        web3Connected: true,
        web3NetworkId: chainId,
        web3Network: networkName,
        ccatContract: cryptoCat,
        wccatContract: wrappedCryptoCat
      });

      provider.request({ method: 'eth_accounts' })
      .then((accounts) => {
        console.log("Connected MM Accounts : ", accounts);
        this.connectWeb3Account(accounts)
      })
      .catch((err) => {
        // Some unexpected error.
        console.error(err);
      });

      provider.on('chainChanged', this.handleWeb3ProviderChainChanged);

      provider.on('accountsChanged', this.connectWeb3Account);

    } else {
      console.error('Please install MetaMask!');
    }
  }

  handleWeb3ProviderChainChanged() {
    window.location.reload();
  }

  showWrapModal(catId) {
    this.setState({ catIdToWrap: catId });
    console.log("Wrap Cat modal open for cat #" + catId);
  }

  showUnwrapModal(catId) {
    this.setState({ catIdToUnwrap: catId });
    console.log("Unwrap Cat modal open for cat #" + catId);
  }

  async connectWeb3Account(accounts) {
    var that = this;
    if (accounts[0]) {
      console.log("Wallet Address from MM => " + accounts[0]);
      this.setState({
        walletAddress: accounts[0],
        showWeb3ConnectButton: false,
        catListIsLoading: true
      });
      this.getCCATowned(accounts[0], (ccatList) => {
        that.setState({ 
          ccatOwnedByAddress: ccatList,
          catListIsLoading: false
        })        
      });
      this.getWCCATowned(accounts[0], (wccatList) => {
        that.setState({ 
          wccatOwnedByAddress: wccatList,
          catListIsLoading: false
        })        
      });
    } else {
      console.log("Empty Wallet Address from MM");
      this.setState({
        showWeb3ConnectButton: true,
        catListIsLoading: false
      });  
    }

  }

  toggleFilterType(catType) {
    var that = this;
    console.log("Clicked filter by => " + catType);
    that.setState({
      catListIsLoading: true
    })

    if (catType === "CCAT") {
      if (!that.state.showWeb3ConnectButton) {
        this.getCCATowned(that.state.walletAddress, (ccatList) => {
          that.setState({ 
            ccatOwnedByAddress: ccatList,
            catListIsLoading: false
          })        
        });        
      }
    
      that.setState({ 
        filterType: "CCAT"
      })
    } else if (catType === "WCCAT") {
      if (!that.state.showWeb3ConnectButton) {
        that.getWCCATowned(that.state.walletAddress, (wccatList) => {
          that.setState({ 
            wccatOwnedByAddress: wccatList,
            catListIsLoading: false
          })        
        });
      }

      that.setState({ 
        filterType: "WCCAT" 
      })
    }
  }

  getShortWalletAddress() {
    return this.state.walletAddress.substring(0,5) + "..." + this.state.walletAddress.substring(this.state.walletAddress.length - 4)
  }

  render() {
    console.log("RENDER PATH ===>", this.state.urlPath);
    return (
      <div className="App">
        <WrapModal triggerReload={this.reloadCats} catId={this.state.catIdToWrap} owner={this.state.walletAddress} ccat={this.state.ccatContract} wccat={this.state.wccatContract}/>
        <UnwrapModal triggerReload={this.reloadCats} catId={this.state.catIdToUnwrap} owner={this.state.walletAddress} wccat={this.state.wccatContract}/>
        <Web3Banner web3Network={this.state.web3Network} walletAddress={this.state.walletAddress} displayConnectBtn={this.state.showWeb3ConnectButton} connectWeb3Account={this.connectWeb3Account}/>
        <Header />
        <div className="container-fluid">
          <div className="menu d-none d-md-block">
            <div className="list-group">
              <a target="_blank" rel="noreferrer" href="https://www.jamleopard.com" className="list-group-item hvr-underline-from-left text-uppercase menu-feature-highlight1">JAM LEOPARD T-SHIRTS</a>
              <a href="/" className="list-group-item hvr-underline-from-left text-uppercase">HOME</a>
              <a href="/wrap" className="list-group-item hvr-underline-from-left text-uppercase">WRAP</a>
              <a target="_blank" rel="noreferrer" href="https://opensea.io/collection/wrappedcryptocats" className="list-group-item hvr-underline-from-left text-uppercase">BUY/SELL ON OPENSEA</a>
              <a href="/catalogue" className="list-group-item hvr-underline-from-left text-uppercase">CATALOGUE</a>
              <a href="/about" className="list-group-item hvr-underline-from-left text-uppercase">ABOUT</a>
              <div id="contact-sidebar" className="list-group-item">
              <address>
                <strong>LICENSE DETAILS</strong><br/>
                <div className="licence-text">Whilst you are the owner of a CryptoCat NFT you can use the artwork for your own personal, non-commercial use including displaying in digital or physical formats. For commercial uses, please reach out to us for approval first.</div>
                <br/>
                    <div className="social-area">
                      <div className="social-icon-col">
                        <a href="https://twitter.com/CryptoCats26" target="_blank" rel="noreferrer">
                          <img src="/twitter-logo.png" className="social-icon-img" alt="twitter"></img>
                        </a>
                      </div>
                      <div className="social-icon-col">
                        <a href="https://discord.gg/T2Twu4VuMC" target="_blank" rel="noreferrer">
                          <img src="/discord-logo.png" className="social-icon-img" alt="discord"></img>
                        </a>
                      </div>
                      <div className="social-icon-col">
                        <a href="mailto:cryptocats@thetwentysix.io" target="_blank" rel="noreferrer">
                          <img src="/email-logo.png" className="social-icon-img" alt="email"></img>
                        </a>
                      </div>                      
                    </div>  
              </address>
            </div>
          </div>
        </div>
        <div className="main">
          <div className="d-xs-block d-sm-block d-md-none">
            <nav id="mobile-menu" className="navbar navbar-light bg-transparent">
              <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarMobileToggle" aria-controls="navbarMobileToggle" aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"></span>
              </button>
            </nav>
            <div className="collapse" id="navbarMobileToggle">
              <div className="bg-light p-3 mb-2 align-center">
                <a target="_blank" rel="noreferrer" href="https://www.jamleopard.com" className="list-group-item hvr-underline-from-left text-uppercase menu-feature-highlight1">JAM LEOPARD T-SHIRTS</a>
                <a href="/" className="list-group-item hvr-underline-from-left text-uppercase">HOME</a>
                <a href="/wrap" className="list-group-item hvr-underline-from-left text-uppercase p-1">WRAP</a>
                <a target="_blank" rel="noreferrer" href="https://opensea.io/collection/wrappedcryptocats" className="list-group-item hvr-underline-from-left text-uppercase p-1">BUY/SELL ON OPENSEA</a>
                <a href="/catalogue" className="list-group-item hvr-underline-from-left text-uppercase p-1">CATALOGUE</a>
                <a href="/about" className="list-group-item hvr-underline-from-left text-uppercase p-1">ABOUT</a>
              </div>
            </div>
          </div>

          <div className="body-header">
            { this.state.urlPath && this.state.urlPathSplit[1] !== "wrap" &&
              this.state.urlPath !== "/about" && this.state.urlPath !== "/catalogue" && 
              !(this.state.urlPathSplit[1] === "cat" && this.state.urlPathSplit.length > 2) &&     
              <div className="container">
                <div>
                  <iframe mozallowfullscreen="true" title="cryptocatworld"
                    allow="autoplay; fullscreen *; midi; xr-spatial-tracking; gamepad; xr" 
                    frameborder="0" 
                    src="/catworld.html" 
                    msallowfullscreen="true" 
                    scrolling="no" 
                    allowfullscreen="true" 
                    webkitallowfullscreen="true" 
                    id="game_frame" 
                    allowtransparency="true">
                  </iframe>        
                </div>

                {/* <div className="gm4html5_div_class" id="gm4html5_div_id">
                  <canvas id="canvas" height="480" width="720">
                    <p>Your browser doesn't support HTML5 canvas.</p>
                  </canvas>
                </div> */}
              </div>
            }

            { this.state.urlPath && this.state.urlPath === "/catalogue" &&
              <div className="container">
                <Explorer />
              </div>
            }

            { this.state.urlPath && this.state.urlPathSplit[2] && this.state.urlPathSplit[1] === "cat" &&  parseInt(this.state.urlPathSplit[2]) >= 0 && parseInt(this.state.urlPathSplit[2]) < 625 &&
              <div className="container">
                <Detail catId={this.state.urlPathSplit[2]} 
                  handleBuyCat={this.doBuyCat} 
                  showWeb3Connect={this.state.showWeb3ConnectButton} 
                  connectWeb3Account={this.connectWeb3Account}
                  web3CryptoCat={this.state.ccatContract} 
                  metamaskAddress={this.state.walletAddress}
                  // showWrapModal={this.showWrapModal}
                  />
              </div>
            }
            
            { this.state.urlPath && (this.state.urlPath === "/wrap" || (this.state.urlPathSplit && this.state.urlPathSplit[2] && this.state.urlPathSplit[1] === "wrap" && this.state.urlPathSplit[2] !== "")) && 
              <div>
                <div id="alert-content" className="alert alert-info" role="alert" style={{display: "none"}}></div>

                { this.state.showWeb3ConnectButton &&
                  <div className="align-center m-top-10pct">
                    <p>Click to connect Metamask wallet and load your CryptoCats</p>
                    <ConnectMetamaskButton connectWeb3Account={this.connectWeb3Account} btnText={"Connect Metamask"} /> 
                  </div>
                }

                <div id="utilities-section" className="container">
                  <div className="row filter-btn-container">
                      <div className="filter-btn-inner">
                      { !this.state.showWeb3ConnectButton &&
                        <FilterButtons toggleFilter={this.toggleFilterType} filterValue={this.state.filterType} catListIsLoading={this.state.catListIsLoading} />
                      }
                      </div>
                  </div>
                </div>

                { this.state.filterType === "CCAT" &&
                  this.state.ccatOwnedByAddress.length === 0 &&
                  !this.state.showWeb3ConnectButton &&
                  <div className="no-cats-label align-center">You have no CCAT tokens</div>
                }

                { this.state.filterType === "WCCAT" &&
                  this.state.wccatOwnedByAddress.length === 0 &&
                  !this.state.showWeb3ConnectButton &&
                  <div className="no-cats-label align-center">You have no WCCAT tokens</div>
                }

                <div className="cards_container">
                    {  this.state.filterType === "CCAT" &&
                      this.state.ccatOwnedByAddress &&
                      this.state.ccatOwnedByAddress.map((idValue, index) => {
                        console.log("CAT ID " + idValue);
                        return <CatCard key={index} catId={idValue} showWrapModal={this.showWrapModal} cardType="CCAT" />
                      })
                    }           

                    {  this.state.filterType === "WCCAT" &&
                      this.state.wccatOwnedByAddress &&
                      this.state.wccatOwnedByAddress.map((idValue, index) => {
                        console.log("CAT ID " + idValue);
                        return <CatCard key={index} catId={idValue} showUnwrapModal={this.showUnwrapModal} cardType="WCCAT" />
                      })
                    }

                </div>              
              </div>
            }

            { this.state.urlPath && this.state.urlPath === "/about" &&
              <About></About>
            }

            <div id="mobile-info-section" className="container d-xs-block d-sm-block d-md-none align-center">
              {/* <strong>JOIN THE CONVERSATION</strong><br/> */}
              <div className="social-area">
                <div className="social-icon-col">
                  <a href="https://twitter.com/CryptoCats26" target="_blank" rel="noreferrer">
                    <img src="/twitter-logo.png" className="social-icon-img" alt="twitter"></img>
                  </a>
                </div>
                <div className="social-icon-col">
                  <a href="https://discord.gg/T2Twu4VuMC" target="_blank" rel="noreferrer">
                    <img src="/discord-logo.png" className="social-icon-img" alt="discord"></img>
                  </a>
                </div>
                <div className="social-icon-col">
                  <a href="mailto:cryptocats@thetwentysix.io" target="_blank" rel="noreferrer">
                    <img src="/email-logo.png" className="social-icon-img" alt="email"></img>
                  </a>
                </div>                      
              </div> 
              <br/><br/>
              <strong>LICENSE DETAILS</strong><br/>
              <div className="licence-text">Whilst you are the owner of a CryptoCat NFT you can use the artwork for your own personal, non-commercial use including displaying in digital or physical formats. For commercial uses, please reach out to us for approval first.</div>
            </div>
          </div>
        </div>

      </div>
    </div>
    );
  }

}

export default App;
