import React, { Component } from 'react';

class ConnectMetamaskButton extends Component {
  constructor(props) {
    super(props);
    this.connectToMetamask = this.connectToMetamask.bind(this);
  }

  async connectToMetamask() {
    console.log("Connect Metamask button clicked");

    if (typeof window.ethereum !== 'undefined') {
      console.log('Browser Web3 provider is installed');
      window.ethereum.request({ method: 'eth_requestAccounts' }).then((accounts) => {
        this.props.connectWeb3Account(accounts);
      }).catch((err) => {
        console.log("Error connecting accounts.");
        console.log(err);
      })
      
    }
  }

  render() {
    return (
      <button className="btn btn-sm btn-primary" onClick={() => this.connectToMetamask()}>{this.props.btnText}</button>
    );
  }
}

export default ConnectMetamaskButton;