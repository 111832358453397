import React, { Component } from 'react';
import ccatData from '../catMetadata.json';
import ConnectMetamaskButton from '../components/ConnectMetamaskButton';
import loadingSpinner from '../assets/img/spinner.svg';

function getUniqueAttributesColour(catData, callBack) {
  var uniqueAttributes = [];
  var uniqueColours = [];
  catData.map((cat) => {
    // console.log(cat.id, uniqueArray);
    cat.attributes.forEach((attrib) => {
      if (!uniqueAttributes.includes(attrib)) {
        uniqueAttributes.push(attrib);
      }
    })

    if (cat.colour && uniqueColours.includes(cat.colour) === false) {
      uniqueColours.push(cat.colour);
    }
    return (uniqueAttributes, uniqueColours);
  })

  callBack(uniqueAttributes.sort(), uniqueColours.sort())
}

var uniqueAttributesList = [];
var uniqueColoursList = [];

getUniqueAttributesColour(ccatData,
  (attributes, colours) => {
    uniqueAttributesList = attributes;
    uniqueColoursList = colours;
  }
)

var catStories = {
  "Nacho Cat": "Gendry has a pug dog called Nacho, we can all see that this cat still has a touch of Nacho's pug character in it.",
  "Scruffy Cat": "We call this little guy the scruffy cat because of his distinct scruffy tail.",
  "Yoko Cat": "This cat is based on a real cat Jochy's cat Yoko.",
  "Fat Cat": "This one is the fattest of the cats, so we've always called him Fat Cat.",
  "Bokky Cat": "As part of the Australian Special Edition release, we created a new special base cat inspired by Bokky PooBah's cat, Princess Leia Peach",
  "Punk Kitten": "This vicious little kitten was made by John from Larvalabs, the awesome guys who created the Cryptopunks.",
  "Fried Chicken Cat": "This cat was made especially for String Nguyen who has a particular love for fried chicken.",
  "Lucky Cat": "This cat is based on Maneki-neko, a cat who brings good luck to the owner."
}

class Detail extends Component {
    constructor(props) {
      super(props);

      this.state = {
        display: true,
        attributeFilterVal: "",
        colourFilterVal: "",
        typeFilterVal: "",
        releaseFilterVal: "",
        attrCountFilterVal: "",
        filteredCatList: ccatData,
        uniqueAttributesList,
        uniqueColoursList,
        catInfo: ccatData[this.props.catId],
        details: {},
        buyTxInProgress: false,
        buyTxHashAvailable: false,
        buyTxHash: "",
        buyComplete: false,
        nameTxInProgress: false,
        nameTxHashAvailable: false,
        nameTxHash: "",
        nameComplete: false,
        stopSaleTxInProgress: false,
        stopSaleTxHashAvailable: false,
        stopSaleTxHash: "",
        stopSaleComplete: false,
        isOwner: false,
        nameValue: ""
      }
  
      this.handleNameCat = this.handleNameCat.bind(this);
      this.onChangeNameInput = this.onChangeNameInput.bind(this);
      this.openWrapper = this.openWrapper.bind(this);
      this.handleBuyCat = this.handleBuyCat.bind(this);
      this.handleStopSaleOfCat = this.handleStopSaleOfCat.bind(this);
    }

    componentDidMount = () => {  
      this.getCatInfo(this.props.catId);
    }    

    getCatInfo = (catId) => {
      var that = this;
      const ccatApiUrl = process.env.REACT_APP_API_URL;
      fetch(ccatApiUrl + '/api/cats/' + catId)
        .then(response => response.json())
        .then((data) => { 
          console.log("Name Data => ", data);
          if (data.name.length > 0) {
            var unescapedUppercaseName = data.name.replace(/\\'/g, "'").replace(/\\"/g, '"').toUpperCase();
            data.unescapedUppercaseName = unescapedUppercaseName;
          }
          
          that.setState({details: data});
        });
    };

    handleNameCat = () => {
      console.log(this.state.nameValue);
      this.setState({ nameTxInProgress: true });
      this.props.web3CryptoCat.nameCat(this.props.catId, this.state.nameValue, this.state.details.owner,
        (txHash) => {
          this.setState({ nameTxHash: txHash, nameTxHashAvailable: true });
        },
        (confirmations, receipt) => {
          console.log("Naming successfull");
          var details = this.state.details;
          details.name = this.state.nameValue;
          details.unescapedUppercaseName = this.state.nameValue.toUpperCase();
          this.setState({
            nameValue: "",
            details
          })
          this.setState({nameTxInProgress: false, nameTxHash: "", nameTxHashAvailable: false, nameComplete: true });
        },
        (err) => {
          console.log("Error occured", err);
          this.setState({nameTxInProgress: false, nameComplete: false });
        })
    }

    handleBuyCat = () => {
      console.log(`Trigger Buy Cat ${this.props.catId} for ${this.state.details.salePriceEth} / ${this.state.details.salePriceWei}`);
      this.setState({ buyTxInProgress: true });
      this.props.web3CryptoCat.buyCat(this.props.catId, this.state.details.salePriceWei, this.props.metamaskAddress,
        (txHash) => {
          this.setState({ buyTxHash: txHash, buyTxHashAvailable: true });
        },
        (confirmations, receipt) => {
          var details = this.state.details;
          details.owner = this.props.metamaskAddress.toLowerCase();
          details.forSale = false;
          this.setState({
            details
          })
          this.setState({buyTxInProgress: false, buyTxHash: "", buyTxHashAvailable: false, buyComplete: true });
        },
        () => {
          console.log("Error occured");
          this.setState({buyTxInProgress: false, buyComplete: false });
        })
    }

    handleStopSaleOfCat = () => {
      console.log(`Trigger Stop Sale Of Cat ${this.props.catId} for ${this.state.details.salePriceEth} / ${this.state.details.salePriceWei}`);
      this.setState({ stopSaleTxInProgress: true });
      this.props.web3CryptoCat.stopSaleOfCat(this.props.catId, this.state.details.owner,
        (txHash) => {
          this.setState({ stopSaleTxHash: txHash, stopSaleTxHashAvailable: true });
        },
        (confirmations, receipt) => {
          var details = this.state.details;
          details.forSale = false;
          this.setState({
            details
          })
          this.setState({stopSaleTxInProgress: false, stopSaleTxHash: "", stopSaleTxHashAvailable: false, stopSaleComplete: true });
        },
        () => {
          console.log("Error occured");
          this.setState({stopSaleTxInProgress: false, stopSaleComplete: false });
        })
    }

    onChangeNameInput = (e) => {
      // console.log(e);
      // console.log(e.target.value);
      this.setState({ nameValue: e.target.value});
      console.log(this.props.web3CryptoCat);

    }

    openWrapper = () => {
      // this.props.showWrapModal(this.props.catId);
      var wrapUrl = "/wrap";
      if (this.state.details.contract === "wccat") {
        wrapUrl = wrapUrl + "/wccat";
      }
      window.open(wrapUrl , "_self", "", false); 
    }

    render() {
      return (
        <div className="cat-detail-container">
          <div className="cat-detail-area">
            <div className="cat-detail-name">
              {this.state.details && this.state.details.unescapedUppercaseName}
            </div>
            <img src={"/contents/images/cats/" + this.props.catId + ".png"} id="cat-image" className="cat-detail-main-img center-block img-rounded" alt="Cat"></img>
            {/* CCAT Buy Button */}
            {this.state.details && this.state.details.forSale === true && this.state.details.salePriceEth && !this.state.buyComplete &&
              <div className="cat-detail-action-area">
                <div className="cat-desc-text">Cat for sale by owner for <strong>{this.state.details.salePriceEth + " ETH"}</strong></div>
                { this.props.showWeb3Connect && 
                  <ConnectMetamaskButton connectWeb3Account={this.props.connectWeb3Account} btnText="Connect Metamask to Buy" /> 
                }
                { !this.props.showWeb3Connect && !this.state.buyTxInProgress && this.state.details.owner !== this.props.metamaskAddress &&
                  <button className="btn btn-primary" onClick={() => this.handleBuyCat()}>Buy</button>
                }
                { this.state.buyTxInProgress && 
                  <div className="width-100">
                    <img src={loadingSpinner} className="wrap-tx-spinner" alt="spinner" />
                    <div>
                      { !this.state.buyTxHashAvailable && 
                        <i>(please approve in metamask...)</i>
                      }
                      { this.state.buyTxHashAvailable && 
                        <i>(waiting for network confirmation...<a target="_blank" rel="noreferrer" href={"https://etherscan.io/tx/"+this.state.buyTxHash}>view</a>)</i>
                      }
                    </div>
                  </div>
                }
              </div>
            }
            { this.state.buyComplete &&
              <div className="cat-detail-action-area">
                <div className="cat-desc-text"><i>Congratulations! You are now the owner</i></div>
              </div>
            }
            {/* WCCAT View with OS button */}
            {this.state.details && this.state.details.contract === "wccat" &&  
              <div className="cat-detail-action-area">
                <a className="btn btn-primary" target="_blank" rel="noreferrer" href={"https://opensea.io/assets/0xd0e7bc3f1efc5f098534bce73589835b8273b9a0/" + this.props.catId}>Buy/Sell on OpenSea</a>
              </div>
            }
            <div className="cat-detail-info-area">
              <div className="cat-detail-description">
                <div className="cat-desc-title">
                  <span><div id="cat-name">Cat #{this.props.catId}</div></span>
                </div>
                <div id="cat-baseinfo" className="cat-desc-text">
                  <strong>{this.state.catInfo.type}</strong>
                  <br/>{this.state.catInfo && catStories[this.state.catInfo.type]}
                  <br/>
                </div>
                {/* CAT OWNER SECTION */}
                { this.props.showWeb3Connect && 
                <div className="mb-5">
                  <ConnectMetamaskButton connectWeb3Account={this.props.connectWeb3Account} btnText={"Connect Metamask for owner features"} /> 
                </div>
                }
                { !this.props.showWeb3Connect && (this.props.metamaskAddress.toLowerCase() === this.state.details.owner) &&
                  <div>
                    <div className="cat-desc-title">
                      <span><div id="cat-name">CAT OWNER UTILITIES</div></span>
                    </div>
                    <div id="cat-baseinfo" className="cat-desc-text">
                      <strong>Cat Name</strong>
                      { this.state.details.contract === "ccat" && 
                        <div>Give your cat a name or set a new name for your cat. The name is stored on the blockchain.</div>
                      }       
                      { this.state.details.contract === "wccat" && 
                        <div>You need to Unwrap your cat first to give it a name or set a new name for your cat. Then you can wrap it again to get it back on the Opensea store</div>
                      }               
                      { !this.props.showWeb3Connect && !this.state.nameTxInProgress && this.state.details.contract === "ccat" &&
                        <div className="cat-util-function">
                          <input id="input-cat-name" type="text" className="tx-input-field input-sm col-xs-5" placeholder="New cat name" value={this.state.nameValue} onChange={(e) => this.onChangeNameInput(e)}></input>
                          <button id="name-cat-btn" className="btn-sm btn-default transaction-button" onClick={this.handleNameCat}>Save</button>
                          { this.state.nameComplete &&
                            <div><i>All done. Now that's a brilliant name!</i></div>
                          }
                        </div>                      
                      }
                      { this.state.nameTxInProgress && 
                        <div className="width-100">
                          <img src={loadingSpinner} className="wrap-tx-spinner inline" alt="spinner" />
                          <div className="inline">
                            { !this.state.nameTxHashAvailable && 
                              <i>(please approve in metamask...)</i>
                            }
                            { this.state.nameTxHashAvailable && 
                              <i>(waiting for network confirmation...<a target="_blank" rel="noreferrer" href={"https://etherscan.io/tx/"+this.state.nameTxHash}>view</a>)</i>
                            }
                          </div>
                        </div>
                      }
                    </div>  
                    {/* CAT TRADING */}
                    { !this.props.showWeb3Connect && this.state.details.contract === "ccat" && this.state.details.forSale === true &&
                      <div id="cat-baseinfo" className="cat-desc-text">
                        <strong>Cat Trading</strong>
                        <div>Your cat is up for sale. Check detail below or cancel sale by clicking button below</div>
                        { !this.props.showWeb3Connect && !this.state.stopSaleTxInProgress &&
                          <div className="width-100">
                            <input id="input-cat-sale-priceEth" type="text" className="tx-input-field input-sm col-xs-5" placeholder={this.state.details.salePriceEth + " ETH"} disabled></input>
                            <button id="stop-sale-cat-btn" className="btn-sm btn-default transaction-button" onClick={this.handleStopSaleOfCat}>Cancel Sale</button>
                          </div>
                        }
                        { this.state.stopSaleTxInProgress && 
                          <div className="width-100">
                            <img src={loadingSpinner} className="wrap-tx-spinner inline" alt="spinner" />
                            <div className="inline">
                              { !this.state.stopSaleTxHashAvailable && 
                                <i>(please approve in metamask...)</i>
                              }
                              { this.state.stopSaleTxHashAvailable && 
                                <i>(waiting for network confirmation...<a target="_blank" rel="noreferrer" href={"https://etherscan.io/tx/"+this.state.stopSaleTxHash}>view</a>)</i>
                              }
                            </div>
                          </div>
                        }
                      </div>
                    }
                    { !this.props.showWeb3Connect && !this.state.stopSaleTxInProgress && this.state.details.contract === "ccat" && (!this.state.details.forSale || this.state.buyComplete ) && 
                      <div id="cat-baseinfo" className="cat-desc-text">
                          <strong>Cat Trading</strong>
                          <div>Your cat is not for sale.</div>
                      </div>                    
                    }
                    {/* CAT WRAP DETAILS */}
                    { this.state.details.contract === "ccat" && 
                      <div id="cat-baseinfo" className="cat-desc-text">
                        <strong>Wrap Your Cat</strong>
                        <div>Swap your CCAT vintage NFT for a WCCAT Wrapped ERC721 token. This allows you to buy/sell on Opensea on our official store and use in the modern NFT metaverse.</div>     
                        <button id="goto-cat-wrapper-btn" className="btn-sm btn-default goto-wrapper-button" onClick={this.openWrapper}>Go To Cat Wrapper</button>                 
                      </div>
                    }
                    { this.state.details.contract === "wccat" && 
                      <div id="cat-baseinfo" className="cat-desc-text">
                        <strong>Wrap Your Cat</strong>
                        <div>Use the Cat Wrapper to Wrap or Unwrap your cat. Wrapped Cats have full functionality on our <a href={"https://opensea.io/assets/0xd0e7bc3f1efc5f098534bce73589835b8273b9a0/"+this.props.catId} target="_blank" rel="noreferrer" >official store on Opensea</a>. Unwrapping allows you to name your cat.</div>     
                        <button id="goto-cat-wrapper-btn" className="btn-sm btn-default goto-wrapper-button" onClick={this.openWrapper}>Go To Cat Wrapper</button>                 
                      </div>
                    }
                  </div>
                }
              </div>
              <div className="cat-detail-traits">
                <div className="cat-traits-title">
                  <span>Traits</span>
                </div>
                <ul className="cat-traits-list">
                  <li><strong>Type</strong><span id="cat-type">{this.state.catInfo.type}</span></li>
                  <li><strong>Attributes</strong><span id="cat-attributes">{this.state.catInfo.attributes.join(", ")}</span></li>
                  <li><strong>Release</strong><span id="cat-release">{this.state.catInfo.release.split("#")[1]}</span></li>
                  <li><strong>Colour</strong><span id="cat-colour">{this.state.catInfo.colour}</span></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      )
    }
}

export default Detail