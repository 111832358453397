
import React, { Component } from 'react';
import loadingSpinner from '../assets/img/spinner.svg';

class UnwrapModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      unwrapComplete: false,
      unwrapInProgress: false,
      unwrapHashAvailable: false,
    }

    this.triggerUnwrap = this.triggerUnwrap.bind(this);
    this.triggerClose = this.triggerClose.bind(this);
  }

  triggerUnwrap() {
    var that = this;
    if (this.props.wccat) {
      var wrappedCryptoCat = this.props.wccat;
      console.log("Check WCCAT Contract", wrappedCryptoCat);
      // cryptoCat.checkForSaleToWrapperStatus(this.props.catId, wccatAddress);

      this.setState({
        unwrapInProgress: true
      });

      wrappedCryptoCat.callUnwrap(this.props.catId, this.props.owner,  
        (txHash) => {
          console.log("Transaction Sent! Tx hash: " + txHash);
          that.setState({
            unwrapHashAvailable: true
          })
        },
        (confirmReceipt) => {
          console.log("Transaction Confirmed! Tx receipt: " + confirmReceipt);
          that.setState({
            unwrapInProgress: false,
            unwrapComplete: true,
            unwrapHashAvailable: false
          });
        },
        (err) => {
          console.log("Error Occurred", err);
          this.setState({
            unwrapInProgress: false,
            unwrapHashAvailable: false
          });
        }
      )
    }
  }

  triggerClose() {
    this.setState({
      unwrapComplete: false,
      unwrapInProgress: false,
      unwrapHashAvailable: false
    });

    this.props.triggerReload();
  }

  render() {
    return (
      <div className="modal fade" id="unwrapCatModal" tabIndex="-1" role="dialog" aria-labelledby="unwrapCatModalCenter" aria-hidden="true" data-backdrop="static" data-keyboard="false">
        <div className="modal-dialog modal-lg" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="unwrapCatModalTitle">Unwrap Your CryptoCat</h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.triggerClose}></button>
            </div>
            <div className="modal-body" id="wrap-modal-body">
              <div className="row d-flex justify-content-center">
              { this.props.catId &&
                <div className="col-6 col-lg-4 align-center">
                  <img id="wrap-image" alt="cryptocat" src={"https://cryptocats.thetwentysix.io/contents/images/cats/" + this.props.catId + ".png"} className="cat-img"></img>
                  <h4>Cat# {this.props.catId}</h4>
                </div>
              }
              </div>            
              <div className="row">
                <div className="col-12 align-center">
                  <p>Unwrapping converts your Wrapped CryptoCat ECR721 token (WCCAT) back to the original CCAT token on our CryptoCat contract.</p>
                </div>
              </div>
              <div className="row align-center" id="wrap-steps">
                { !this.state.unwrapComplete && !this.state.unwrapInProgress &&
                  <div className="col-12">
                    <button className="btn btn-sm btn-primary" onClick={this.triggerUnwrap}>Unwrap Now</button>
                  </div>
                }
                { this.state.unwrapInProgress && 
                  <div className="width-100">
                    <img src={loadingSpinner} className="wrap-tx-spinner" alt="spinner" />
                    <div>
                      { !this.state.unwrapHashAvailable && 
                        <i>(please approve in metamask...)</i>
                      }
                      { this.state.unwrapHashAvailable && 
                        <i>(waiting for network confirmation...)</i>
                      }
                    </div>
                  </div>
                }
              </div>
              { this.state.unwrapComplete && 
                <div className="row align-center" id="wrap-successful">
                  <div className="col-12">
                    <h5>All done! Your CryptoCat is now unwrapped</h5>
                  </div>
                </div>
              }
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default UnwrapModal;