import React, { Component } from 'react';
import classNames from './css/About.module.css';

import cats625Img from '../assets/img/625cats.png';
import wrappedCatsOpenseaImg from '../assets/img/wrappedCatsOpensea.png';
import punkCatsImg from '../assets/img/punkCats.png';
import cat52Img from '../assets/img/cat52about.png';
import cats12Img from '../assets/img/12cats.png';
import hackathonImg from '../assets/img/hackathon.png';
import upCatImg from '../assets/img/upcat.png';

class About extends Component {
    constructor(props) {
      super(props);
      this.state = {
        display: true
      }
  
    }

    render() {
        return (
            <div className={classNames.container}>
                <div className={classNames.header}>
                    <div className={classNames.header_image}>
                        <div className={classNames.img}>
                            <img src={cats625Img} alt="625-cats" />
                        </div>
                        <div className={classNames.title}>
                            <p>625 Tokens, an NFT artwork by Cat1</p>
                        </div>
                    </div>
                    <div className={classNames.header_text}>
                        <div className={classNames.title}>
                            <h1>A Collection of 625 NFTs</h1>
                        </div>
                        <div className={classNames.subtitle}>
                            <p>Deployed to the Ethereum Mainnet on 12th November 2017, CryptoCats are one of the scarcest NFT collections from that time with only 625 tokens. Launched before the CryptoKitties, it was a time before the ERC721 standard so there are some pretty  cute function names in the smart contract. <br/> Each CryptoCat is unique and most are  programmatically generated with different accessories.</p>
                        </div>
                    </div>
                </div>
                <div className={classNames.releases}>
                    <div className={classNames.releases_header}>
                        <div className={classNames.title}>
                            <h1>Official Releases</h1>
                        </div>
                        <div className={classNames.subtitle}>
                            <p>There are 3 releases that make up the CryptoCats collection. Each time we  launched a new release, the official NFTs from the previous release were migrated to the new contract. The final release of CryptoCats was on 24th December 2017 and is  currently the Official Contract of the CCAT Vintage tokens. <br/>  All CCAT Vintage NFT transaction history can be found by looking up the contract owner address: 0xd7148578159b87a9EFA2f0290531B44b0f9063D1</p>
                        </div>
                    </div>
                    <div className={classNames.releases_cards}>
                        <div className={classNames.releases_cards_inner}>
                            <div className={classNames.card}>
                                <div className={classNames.c_header}>
                                    <h3>1st Release</h3>
                                    <p>12th November 2017</p>
                                    <p>12 Cats</p>
                                    <p>0xC4AA7a486c054E6F277712C7D82DB1A3A1DBE758</p>
                                </div>
                                <div className={classNames.c_img}>
                                    <div className={classNames.img}>
                                        <img src={cats12Img} alt="12-cats" />
                                    </div>
                                    <div className={classNames.title}>
                                        <p>First 12 cats</p>
                                    </div>
                                </div>
                                <div className={classNames.c_text}>
                                    <p>The Original 12 Cats were each made by hand and it was the first smart contract and web3 integration we had built. We always felt like the little guys and when we heard about the CryptoKitties and their big team we decided to beat them to mainnet in 2017 with our release.</p>
                                </div>
                            </div>
                            <div className={classNames.card}>
                                <div className={classNames.c_header}>
                                    <h3>2nd Release</h3>
                                    <p>25th November 2017</p>
                                    <p>177 Cats</p>
                                    <p>0xa185b9e63fb83a5a1a13a4460b8e8605672b6020</p>
                                </div>
                                <div className={classNames.c_img}>
                                    <div className={classNames.img}>
                                        <img src={hackathonImg} alt="hackathon" />
                                    </div>
                                    <div className={classNames.title}>
                                        <p>The team presenting at the hackathon</p>
                                    </div>
                                </div>
                                <div className={classNames.c_text}>
                                    <p>The second release was launched at the Blockathon, an iconic blockchain hackathon in Sydney, Australia. All cats were claimed within hours and we were blown away when they announced we won first place.</p>
                                </div>
                            </div>
                            <div className={classNames.card}>
                                <div className={classNames.c_header}>
                                    <h3>3rd & Final Release</h3>
                                    <p>24th December 2017</p>
                                    <p>436 Cats</p>
                                    <p>0x088C6Ad962812b5Aa905BA6F3c5c145f9D4C079f</p>
                                </div>
                                <div className={classNames.c_img}>
                                    <div className={classNames.img}>
                                        <img src={upCatImg} alt="up-cat" />
                                    </div>
                                    <div className={classNames.title}>
                                        <p>"Up Only" the vibes after the rediscovery</p>
                                    </div>
                                </div>
                                <div className={classNames.c_text}>
                                    <p>The final release was launched in December, with an initial mint price in our attempt to manage the constant demand for us to make more cats. The last release was largely unsold until it was discovered in 2021 and the remaining cats sold out in minutes.</p>
                                </div>
                                <div className={classNames.c_link}>
                                    <a href="https://ipfs.io/ipfs/QmdTuvmfyMKpFaUePtmMNHhBfEKL4HkGtnPj4WvevPZbhv" target="_blank" rel="noreferrer">IPFS Composite image link</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={classNames.buysell}>
                    <div className={classNames.title}>
                        <h1>Buy/Sell</h1>
                    </div>
                    <div className={classNames.b_header}>
                        <div className={classNames.img}>
                            <img src={wrappedCatsOpenseaImg} alt="wrapped-cats-opensea" />
                        </div>
                        <div className={classNames.text}>
                            <h4>Wrapped Cats</h4>
                            <p>Established 14th March 2021</p>
                            <p>0xd0e7Bc3F1EFc5f098534Bce73589835b8273b9a0</p>
                            <a href="https://opensea.io/collection/wrappedcryptocats" target="_blank" rel="noreferrer">Opensea Store</a>
                            <a href="/wrap" target="_blank" rel="noreferrer">Cat Wrap UI</a>
                        </div>
                    </div>
                    <div className={classNames.b_text}>
                        <p>More than 3 years later in 2021, the CryptoCats were rediscovered and we have an amazing CryptoCat community! We created a wrapper contract that turns your vintage CryptoCat into an ERC721 Wrapped CryptoCat WCCAT token. This makes it compatible with the modern world of ERC721 NFTs and we have an Opensea store where you can buy/sell </p>
                    </div>
                </div>
                <div className={classNames.famous}>
                    <div className={classNames.famous_inner}>
                        <div className={classNames.footer_text}>
                            <div className={classNames.title}>
                                <h1>Famous Cats</h1>
                            </div>
                            <div className={classNames.subtitle}>
                                <p>The CryptoCats collection includes some rather famous cats, including the Punk Kitten made by Larva Labs. <br /> <br /> Nacho and Yoko cats are based on real world pets from the team, then there's Fat Cat who is always looking like a boss and Scruffy who seems to find the right outfit for any occasion. Plus, there's a few surprise cats that were custom made along the way. </p>
                            </div>
                        </div>
                        <div className={classNames.footerImg}>
                            <div className={classNames.topImg}>
                                <div className={classNames.img}>
                                    <img src={punkCatsImg} alt="punk-cats" />
                                </div>
                                <div className={classNames.title}>
                                    <p>Punk Kittens made by Larva Labs for us</p>
                                </div>
                            </div>
                            <div className={classNames.bottomImg}>
                                <div className={classNames.img}>
                                    <img src={cat52Img} alt="cat-52" />
                                </div>
                                <div className={classNames.title}>
                                    <p>Cat#52</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

}

export default About