import React, { Component } from 'react';
import CatCardExplore from '../components/CatCardExplore';
import ccatData from '../catMetadata.json';

function getUniqueAttributesColour(catData, callBack) {
  var uniqueAttributes = [];
  var uniqueColours = [];
  catData.map((cat) => {
    // console.log(cat.id, uniqueArray);
    cat.attributes.forEach((attrib) => {
      if (!uniqueAttributes.includes(attrib)) {
        uniqueAttributes.push(attrib);
      }
    })

    if (cat.colour && uniqueColours.includes(cat.colour) === false) {
      uniqueColours.push(cat.colour);
    }
    return (uniqueAttributes, uniqueColours);
  })

  callBack(uniqueAttributes.sort(), uniqueColours.sort())
}

var uniqueAttributesList = [];
var uniqueColoursList = [];

getUniqueAttributesColour(ccatData,
  (attributes, colours) => {
    uniqueAttributesList = attributes;
    uniqueColoursList = colours;
  })

class Explorer extends Component {
    constructor(props) {
      super(props);

      this.state = {
        display: true,
        attributeFilterVal: "",
        colourFilterVal: "",
        typeFilterVal: "",
        releaseFilterVal: "",
        attrCountFilterVal: "",
        filteredCatList: ccatData,
        uniqueAttributesList,
        uniqueColoursList,
        catInfo: []
      }
  
      this.applyFilterToCatList = this.applyFilterToCatList.bind(this);
      this.handleAttributeFilterChange = this.handleAttributeFilterChange.bind(this);
      this.handleColourFilterChange = this.handleColourFilterChange.bind(this);
      this.handleCountFilterChange = this.handleCountFilterChange.bind(this);
      this.handleReleaseFilterChange = this.handleReleaseFilterChange.bind(this);
      this.handleTypeFilterChange = this.handleTypeFilterChange.bind(this);
      this.getCatInfo = this.getCatInfo.bind(this);

      this.handleViewCatDetailPage = this.handleViewCatDetailPage.bind(this);

      // this.getUniqueAttributesColour(ccatData);
      // console.log("ATTRIBUTES & COLOURS =>");
      // console.log(uniqueAttributesList);
      // console.log(uniqueColoursList);
    }

    componentDidMount = () => {  
      this.getCatInfo();
    }    

    getCatInfo = () => {
      var that = this;
      const ccatApiUrl = process.env.REACT_APP_API_URL;
      fetch(ccatApiUrl+'/api/allcats')
        .then(response => response.json())
        .then((data) => { 
          console.log("Name Data => ", data);
          that.setState({catInfo: data});
        });
    }



    applyFilterToCatList = (attributeVal, colourVal, typeVal, releaseVal, attrCountVal) => {
      var filteredCatList;

      if (attributeVal !== undefined && attributeVal.trim().length > 0 ) {
        filteredCatList = ccatData.filter((cat) => {
          var attStr = cat.attributes.join(" ");
          var attResult = attStr.includes(attributeVal);
          console.log("filterval", attResult);
          return attResult
        })        
      } else {
        filteredCatList = ccatData;
      }

      if (colourVal !== undefined && colourVal.trim().length > 0 ) {
        filteredCatList = filteredCatList.filter((cat) => {
          var colResult = cat.colour.includes(colourVal);
          return colResult
        })        
      }

      if (typeVal !== undefined && typeVal.trim().length > 0 ) {
        filteredCatList = filteredCatList.filter((cat) => {
          var typeResult = cat.type.includes(typeVal);
          return typeResult
        })        
      }

      if (releaseVal !== undefined && releaseVal.trim().length > 0 ) {
        filteredCatList = filteredCatList.filter((cat) => {
          var releaseResult = cat.release.includes(releaseVal);
          return releaseResult
        })        
      }

      if (attrCountVal !== undefined && attrCountVal.trim().length > 0 ) {
        filteredCatList = filteredCatList.filter((cat) => {
          var attrCountResult = cat.attributeCount.toString().includes(attrCountVal);
          return attrCountResult
        })        
      }

      this.setState({ filteredCatList })
    }

    handleAttributeFilterChange = (e) => {
      const value = e.target.value || undefined;
      this.setState({ attributeFilterVal: value })
      this.applyFilterToCatList(value, this.state.colourFilterVal, this.state.typeFilterVal, this.state.releaseFilterVal, this.state.attrCountFilterVal);     
    }

    handleColourFilterChange = (e) => {
      const value = e.target.value || undefined;
      this.setState({ colourFilterVal: value })
      this.applyFilterToCatList(this.state.attributeFilterVal, value, this.state.typeFilterVal, this.state.releaseFilterVal, this.state.attrCountFilterVal);   
    }

    handleTypeFilterChange = (e) => {
      const value = e.target.value || undefined;
      this.setState({ typeFilterVal: value })
      this.applyFilterToCatList(this.state.attributeFilterVal, this.state.colourFilterVal, value, this.state.releaseFilterVal, this.state.attrCountFilterVal);
    }
    
    handleReleaseFilterChange = (e) => {
      const value = e.target.value || undefined;
      this.setState({ releaseFilterVal: value })
      this.applyFilterToCatList(this.state.attributeFilterVal, this.state.colourFilterVal, this.state.typeFilterVal, value, this.state.attrCountFilterVal);
    }

    handleCountFilterChange = (e) => {
      const value = e.target.value || undefined;
      this.setState({ attrCountFilterVal: value })
      this.applyFilterToCatList();
      this.applyFilterToCatList(this.state.attributeFilterVal, this.state.colourFilterVal, this.state.typeFilterVal, this.state.releaseFilterVal, value);
    }

    handleViewCatDetailPage = (catId) => {
        console.log("load cat detail page for cat " + catId);
        window.location = "/cat/" + catId;
    }

    render() {
        return (
          <div>
            <div className="container">
              <div style={{ marginBottom: "16px"}}>
                <span>Attribute &nbsp;</span>
                {/* <input
                  value={this.state.attributeFilterVal}
                  onChange={this.handleAttributeFilterChange}
                  placeholder={"Search Attributes"}
                /> */}
                <select name="type" id="type" onChange={this.handleAttributeFilterChange}>
                  <option value="">--Select--</option>
                  {
                    this.state.uniqueAttributesList.map((catAttribute) => {
                      return <option value={catAttribute}>{catAttribute}</option>
                    })
                  }
                </select>
                <span>&nbsp;&nbsp;Colour &nbsp;</span>
                {/* <input
                  value={this.state.colourFilterVal}
                  onChange={this.handleColourFilterChange}
                  placeholder={"Search Colour"}
                /> */}
                <select name="type" id="type" onChange={this.handleColourFilterChange}>
                  <option value="">--Select--</option>
                  {
                    this.state.uniqueColoursList.map((catColour) => {
                      return <option value={catColour}>{catColour}</option>
                    })
                  }
                </select>
              </div>
              <div className="pull-right"><h2>Cats: {this.state.filteredCatList.length}</h2></div>
              <div style={{ marginBottom: "30px"}}>
                <span>Type &nbsp;</span>
                <select name="type" id="type" onChange={this.handleTypeFilterChange}>
                  <option value="">--Select--</option>
                  <option value="Punk Kitten">Punk Kitten</option>
                  <option value="Scruffy Cat">Scruffy Cat</option>
                  <option value="Nacho Cat">Nacho Cat</option>
                  <option value="Fat Cat">Fat Cat</option>
                  <option value="Yoko Cat">Yoko Cat</option>
                  <option value="Bokky Cat">Bokky Cat</option>
                  <option value="Lucky Cat">Lucky Cat</option>
                  <option value="Fried Chicken Cat">Fried Chicken Cat</option>
                </select>
                <span>&nbsp;&nbsp;Release &nbsp;</span>
                <select name="type" id="type" onChange={this.handleReleaseFilterChange}>
                  <option value="">--Select--</option>
                  <option value="#1">#1</option>
                  <option value="#2">#2</option>
                  <option value="#3">#3</option>
                </select>
                <span>&nbsp;&nbsp;Attribute Count &nbsp;</span>
                <select name="type" id="type" onChange={this.handleCountFilterChange}>
                  <option value="">--Select--</option>
                  <option value="0">0</option>
                  <option value="1">1</option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                </select>
              </div>
            </div>
            <div className="cards_container">
              { this.state.filteredCatList.map((cat) => {
                  return(<CatCardExplore key={cat.id} cat={cat} catInfo={this.state.catInfo[cat.id]} loadDetailPage={this.handleViewCatDetailPage} />);          
                })
              }
            </div>
          </div>
        )
    }
}

export default Explorer