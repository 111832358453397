import React, { Component } from 'react';

class CatCard extends Component {
  constructor(props) {
    super(props);
    this.displayWrapCatModal = this.displayWrapCatModal.bind(this);
    this.displayUnwrapCatModal = this.displayUnwrapCatModal.bind(this);
  }

  displayWrapCatModal() {
    console.log("Cat Wrap Btn Click");
    this.props.showWrapModal(this.props.catId);
  }

  displayUnwrapCatModal() {
    console.log("Cat Wrap Btn Click");
    this.props.showUnwrapModal(this.props.catId);
  }

  render() {
    return (
      <div className="cat-card">
        <div className="cat-details">
          <div className="cat-img">
            <img alt="cat-card" src={"https://cryptocats.thetwentysix.io/contents/images/cats/"+ this.props.catId +".png"} className="cat-img"></img>
          </div>
          <div className="cat-info-area">

            <div className="cat-label">
              <b>Cat# {this.props.catId}</b>
            </div>

            <div className="cat-button">
              { this.props.cardType === "CCAT" && 
                <button type="button" id="wrap-cat-btn" className="cat-action-btn"  data-toggle="modal" data-target="#wrapCatModal" onClick={this.displayWrapCatModal}>
                  Wrap
                </button>               
              }
              { this.props.cardType === "WCCAT" && 
                <button type="button" id="wrap-cat-btn" className="cat-action-btn"  data-toggle="modal" data-target="#unwrapCatModal" onClick={this.displayUnwrapCatModal}>
                  Unwrap
                </button>               
              }
            </div>
          </div>
        </div>          
      </div>
    );
  }

}

export default CatCard;