import React, { Component } from 'react';

class CatCardExplore extends Component {
  constructor(props) {
    super(props);
    this.displayWrapCatModal = this.displayWrapCatModal.bind(this);
    this.displayUnwrapCatModal = this.displayUnwrapCatModal.bind(this);
  }

  displayWrapCatModal() {
    console.log("Cat Wrap Btn Click");
    this.props.showWrapModal(this.props.catId);
  }

  displayUnwrapCatModal() {
    console.log("Cat Wrap Btn Click");
    this.props.showUnwrapModal(this.props.catId);
  }

  render() {
    var unescapedUppercaseName = this.props.catInfo && this.props.catInfo.name.replace(/\\'/g, "'").replace(/\\"/g, '"').toUpperCase();
    return (
      <div className="cat-card-explore" onClick={() => {this.props.loadDetailPage(this.props.cat.id)}}>
        <div className="cat-card-details">
          <div className="cat-card-img">
            <img alt="cat-card" src={"/contents/images/cats/"+ this.props.cat.id +".png"} className="cat-card-img"></img>
          </div>
          <div className="cat-info-area">
            <div className="cat-card-label">
              <b>Cat# {this.props.cat.id}</b>
            </div>
            <div className="cat-card-name">
              { this.props.catInfo && this.props.catInfo.name && this.props.catInfo.name.length > 0 && 
                <b>{unescapedUppercaseName}</b> 
              }
              { this.props.catInfo && (!this.props.catInfo.name || this.props.catInfo.name.length === 0) && 
                <div className="cat-action-btn detail-wout-name"><span>Details</span></div>
              }
              { this.props.catInfo && this.props.catInfo.name && this.props.catInfo.name.length > 0 && 
                <div className="cat-action-btn detail-with-name">Details</div>
              }              
            </div>
          </div>
        </div>          
      </div>
    );
  }

}

export default CatCardExplore;